import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from "react-i18next";

const ExpiredTokenModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>{t("session_expired")}</ModalHeader>
            <ModalBody>
                {t("session_expired_message")}
            </ModalBody>
            <ModalFooter>
                <Button colorScheme="blue" onClick={onClose}>
                OK
                </Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
};

export default ExpiredTokenModal;