import React, { useState, useEffect } from 'react';
import { Box, Heading, Accordion, AccordionItem, AccordionPanel, AccordionButton, AccordionIcon, Button, Textarea, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useHistory, Prompt } from 'react-router-dom';
import { AuthContext } from "contexts/AuthContext";
import { axiosInstance } from "api/apiClient";
import LoadingSpinner from '../../../components/spinner/spinner';
import ConfirmationModal from '../../../components/confirmation/ConfirmationModal';
import ExpiredTokenModal from 'components/expiredTokenModal/expiredTokenModal';

const Training = () => {
  const { userInfos, signOut } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [assistants, setAssistants] = useState([]);
  const [loadingDoc, setLoadingDoc] = useState(true);
  // Use an object to track which example is selected for each assistant
  const [selectedExamples, setSelectedExamples] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteAssistantId, setDeleteAssistantId] = useState(null);
  const [deleteExampleId, setDeleteExampleId] = useState(null);
  const [tokenExpModalOpen, setTokenExpModalOpen] = useState(false);

  useEffect(() => {
    if (!userInfos || !userInfos.client_id) {
      signOut();
      history.push("/auth");
      return;
    }

    let isMounted = true;

    const fetchDocumentation = async () => {
      try {
        const response = await axiosInstance.get(`assistant/get_client_assistants?client_id=${userInfos.client_id}`);
        if (isMounted) {
          setAssistants(response.data);
          setLoadingDoc(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setTokenExpModalOpen(true);
          setLoadingDoc(false);
        } else {
          console.error("Error fetching documentation:", error);
          if (isMounted) {
            setLoadingDoc(false);
          }
        }
      }
    };

    fetchDocumentation();
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      isMounted = false;
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [userInfos]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const handleTokenExpModalConfirm = () => {
    setTokenExpModalOpen(false);
    signOut();
    history.push("/auth");
  };

  const handleBeforeUnload = (e) => {
    if (unsavedChanges) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  const handleExampleChange = (assistantIndex, exampleIndex, messageIndex, key, value) => {
    const updatedAssistants = [...assistants];
    const targetMessage = updatedAssistants[assistantIndex].prompt.examples[exampleIndex].messages[messageIndex];
    targetMessage[key] = value;
    setAssistants(updatedAssistants);
    setUnsavedChanges(true);
  };

  const handleExampleNameChange = (assistantIndex, exampleIndex, value) => {
    const updatedAssistants = [...assistants];
    updatedAssistants[assistantIndex].prompt.examples[exampleIndex].name = value;
    setAssistants(updatedAssistants);
    setUnsavedChanges(true);
  };

  const addMessagePair = (assistantIndex, exampleIndex) => {
    const newMessages = [
      {
        message_id: Math.random().toString(36).substring(2, 15),
        role: 'user',
        created_at: Date.now(),
        value: ""
      },
      {
        message_id: Math.random().toString(36).substring(2, 15),
        role: 'assistant',
        created_at: Date.now(),
        value: ""
      }
    ];
    const updatedAssistants = [...assistants];
    updatedAssistants[assistantIndex].prompt.examples[exampleIndex].messages.push(...newMessages);
    setAssistants(updatedAssistants);
    setUnsavedChanges(true);
  };

  const removeMessagePair = (assistantIndex, exampleIndex, messageIndex) => {
    const updatedAssistants = [...assistants];
    updatedAssistants[assistantIndex].prompt.examples[exampleIndex].messages.splice(messageIndex, 2);
    setAssistants(updatedAssistants);
    setUnsavedChanges(true);
  };

  const handleDeleteExample = (assistantId, exampleId) => {
    setDeleteAssistantId(assistantId);
    setDeleteExampleId(exampleId);
    setIsModalOpen(true);
  };

  const deleteExample = async () => {
    try {
      await axiosInstance.delete(
        `examples/delete_example?client_id=${userInfos.client_id}&assistant_id=${deleteAssistantId}&example_id=${deleteExampleId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      // Remove the selected example if it was the one being deleted
      setSelectedExamples((prevSelected) => ({
        ...prevSelected,
        [deleteAssistantId]: prevSelected[deleteAssistantId] === deleteExampleId ? null : prevSelected[deleteAssistantId],
      }));

      const updatedAssistants = [...assistants];
      const assistantIndex = updatedAssistants.findIndex(a => a.assistant_id === deleteAssistantId);
      updatedAssistants[assistantIndex].prompt.examples = updatedAssistants[assistantIndex].prompt.examples.filter(ex => ex.id !== deleteExampleId);
      setAssistants(updatedAssistants);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error deleting example:', error);
    }
  };

  const createNewExample = async (assistant) => {
    if (assistant.prompt.examples.length >= 15) {
      alert(t("training.examples_limit_alert"));
      return;
    }
    try {
      const response = await axiosInstance.post(
        `examples/create_example?client_id=${userInfos.client_id}&assistant_id=${assistant.assistant_id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const newExample = response.data;
      
      const updatedAssistants = [...assistants];
      const assistantIndex = assistants.findIndex(a => a.assistant_id === assistant.assistant_id);
      updatedAssistants[assistantIndex].prompt.examples.push(newExample);
      setAssistants(updatedAssistants);

      setSelectedExamples((prevSelected) => ({
        ...prevSelected,
        [assistant.assistant_id]: newExample.id,
      }));
    } catch (error) {
      console.error('Error creating example:', error);
    }
  };

  const saveExamples = async (assistantId, examples) => {
    try {
      await axiosInstance.put(
        `examples/update_examples?client_id=${userInfos.client_id}&assistant_id=${assistantId}`,
        examples,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      alert(t("training.example_saved_alert"));
      setUnsavedChanges(false);
    } catch (error) {
      console.error('Error saving examples:', error);
    }
  };
  
  if (loadingDoc) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      height="100vh"
      pt={{ base: "180px", md: "80px", xl: "80px" }}
      overflowY="auto"
      p="20px"
      fontFamily="Arial, sans-serif"
    >
      <Prompt
        when={unsavedChanges}
        message={t("training.unsaved_changes_alert")}
      />
      <Accordion allowMultiple mb="40px">
        {assistants.map((assistant, i) => (
          <AccordionItem
            key={assistant.assistant_id}
            border="1px solid #ccc"
            borderRadius="8px"
            mb="10px"
          >
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading as="h2" size="md">
                  {assistant.name}
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              {!assistant.prompt ? (
                <Box
                  p="20px"
                  bg="red.100"
                  color="red.700"
                  borderRadius="8px"
                  mb="20px"
                >
                  {t("knowledge_base.null_prompt")}
                </Box>
              ) : (
                <>
                  {!assistant.prompt.examples || assistant.prompt.examples.length === 0 ? (
                    <Box
                      p="20px"
                      bg="red.100"
                      color="red.700"
                      borderRadius="8px"
                      mb="20px"
                    >
                      {t("training.null_prompt")}
                    </Box>
                  ) : (
                    <Box display="flex">
                      <Box flex="0 0 25%" borderRight="1px solid #ccc" display="flex" flexDirection="column">
                        {assistant.prompt.examples.map((example, exampleIndex) => (
                          <Textarea
                            p="10px"
                            key={example.id}
                            bg={example.id === selectedExamples[assistant.assistant_id] ? "blue.100" : "white"}
                            value={example.name}
                            onClick={() => setSelectedExamples((prevSelected) => ({
                              ...prevSelected,
                              [assistant.assistant_id]: example.id,
                            }))}
                            onChange={(e) => handleExampleNameChange(i, exampleIndex, e.target.value)}
                            resize="none"
                            minHeight="40px"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            spellCheck={false}
                          />
                        ))}
                        <Button
                          colorScheme="green"
                          mt="30px"
                          onClick={() => createNewExample(assistant)}
                          width="50%"
                        >
                          {t("training.new_example")}
                        </Button>
                        <Button
                          colorScheme="blue"
                          mt="30px"
                          onClick={() => saveExamples(assistant.assistant_id, assistant.prompt.examples)}
                          width="50%"
                        >
                          {t("save_button")}
                        </Button>
                      </Box>

                      <Box flex="1" p="20px">
                        {selectedExamples[assistant.assistant_id] && (
                          <>
                            <ul>
                              {assistant.prompt.examples
                                ?.find((ex) => ex.id === selectedExamples[assistant.assistant_id])
                                ?.messages.map((message, mi, messages) => (
                                  message.role === 'user' && mi % 2 === 0 && (
                                    <li key={message.message_id} style={{ marginBottom: "20px" }}>
                                      <HStack spacing="10px">
                                        <Text flex="0 0 80px">{t("conversations.user")}</Text>
                                        <Textarea
                                          value={message.value}
                                          onChange={(e) =>
                                            handleExampleChange(i, assistant.prompt.examples.findIndex((ex) => ex.id === selectedExamples[assistant.assistant_id]), mi, 'value', e.target.value)
                                          }
                                          bg="rgb(229, 230, 235)"
                                          resize="none"
                                          minHeight="40px"
                                          width="100%"
                                          overflow="hidden"
                                          spellCheck={false}
                                          onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                                          onBlur={(e) => e.target.style.height = '40px'}
                                        />
                                      </HStack>
                                      <HStack spacing="10px" pt="10px">
                                        <Text flex="0 0 80px">Assistant</Text>
                                        <Textarea
                                          value={messages[mi + 1]?.value || ''}
                                          onChange={(e) =>
                                            handleExampleChange(i, assistant.prompt.examples.findIndex((ex) => ex.id === selectedExamples[assistant.assistant_id]), mi + 1, 'value', e.target.value)
                                          }
                                          color="white"
                                          bg="rgb(37, 77, 127)"
                                          resize="none"
                                          minHeight="40px"
                                          width="100%"
                                          overflow="hidden"
                                          spellCheck={false}
                                          onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                                          onBlur={(e) => e.target.style.height = '40px'}
                                        />
                                        <Button colorScheme="red" onClick={() => removeMessagePair(i, assistant.prompt.examples.findIndex((ex) => ex.id === selectedExamples[assistant.assistant_id]), mi)}>{t("delete")}</Button>
                                      </HStack>
                                    </li>
                                  )
                                ))}
                            </ul>
                            <Button
                              colorScheme="green"
                              mb="10px"
                              onClick={() => addMessagePair(i, assistant.prompt.examples.findIndex((ex) => ex.id === selectedExamples[assistant.assistant_id]))}
                            >
                              {t("training.add_message")}
                            </Button>
                            <Button
                              colorScheme="red"
                              mt="50px"
                              float="right"
                              onClick={() => handleDeleteExample(assistant.assistant_id, selectedExamples[assistant.assistant_id])}
                            >
                              {t("training.delete_example")}
                            </Button>
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={deleteExample}
        title={t("training.delete_example")}
        body={t("training.delete_example_conf")}
      />
      <ExpiredTokenModal isOpen={tokenExpModalOpen} onClose={handleTokenExpModalConfirm} />
    </Box>
  );
}

export default Training;