/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Icon, SimpleGrid, useColorModeValue, Text } from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "api/apiClient";
import { AuthContext } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { MdAttachMoney, MdBarChart } from "react-icons/md";
import ComplexTable from "views/admin/default/components/ComplexTable";
import TotalSpent from "views/admin/default/components/TotalSpent";
import { columnsDataComplex } from "views/admin/default/variables/columnsData";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import LoadingSpinner from '../../../components/spinner/spinner';
import "./Default.css";
import { useHistory } from 'react-router-dom';
import ExpiredTokenModal from "components/expiredTokenModal/expiredTokenModal";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { t } = useTranslation();
  const history = useHistory();

  const { userInfos, signOut } = React.useContext(AuthContext);
  const [conversations, setConversations] = useState([]);
  const [loadingConversations, setLoadingConversations] = useState(true);

  const [leads, setLeads] = useState([]);
  const [loadingLeads, setLoadingLeads] = useState(true);
  const [pageViews, setPageViews] = useState([]);
  const [loadingPageViews, setLoadingPageViews] = useState(true);
  const [chatClicks, setChatClicks] = useState([]);
  const [loadingChatClicks, setLoadingChatClicks] = useState(true);
  const [tokenExpModalOpen, setTokenExpModalOpen] = useState(false);

  useEffect(() => {
    if (!userInfos || !userInfos.client_id) {
      signOut();
      history.push("/auth");
      return;
    }
    let isMounted = true; // Flag to track mount state

    const fetchConversations = async () => {
      try {
        const response = await axiosInstance.get(`firestore/get_conversations?client_id=${userInfos.client_id}`);
        const sortedConvos = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        if (isMounted) {
          setConversations(sortedConvos);
          setLoadingConversations(false); // End loading after setting conversations
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setTokenExpModalOpen(true);
          setLoadingConversations(false);
        } else {
          console.error("Error fetching conversation:", error);
          if (isMounted) {
            setLoadingConversations(false); // End loading if there was an error
          }
        }
      }
    };

    const fetchLeads = async () => {
      try {
        const response = await axiosInstance.get(`firestore/get_leads?client_id=${userInfos.client_id}`);;
        const leadsData = response.data;
        if (isMounted) {
          setLeads(leadsData);
          setLoadingLeads(false); // End loading after setting Leads
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setTokenExpModalOpen(true);
          setLoadingLeads(false);
        } else {
          console.error("Error fetching lead:", error);
          if (isMounted) {
            setLoadingLeads(false); // End loading if there was an error
          }
        }
      }
    };

    const fetchPageViews = async () => {
      try {
        const response = await axiosInstance.get(`firestore/get_page_views?client_id=${userInfos.client_id}`);
        const pageViewsData = response.data;
        if (isMounted) {
          setPageViews(pageViewsData);
          setLoadingPageViews(false); // End loading after setting PageViews
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setTokenExpModalOpen(true);
          setLoadingPageViews(false);
        } else {
          console.error("Error fetching page view:", error);
          if (isMounted) {
            setLoadingPageViews(false); // End loading if there was an error
          }
        }
      }
    };

    const fetchChatClicks = async () => {
      try {
        const response = await axiosInstance.get(`firestore/get_chat_clicks?client_id=${userInfos.client_id}`);
        const chatClicksData = response.data;
        if (isMounted) {
          setChatClicks(chatClicksData);
          setLoadingChatClicks(false); // End loading after setting ChatClicks
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setTokenExpModalOpen(true); 
          setLoadingChatClicks(false);
        } else {
          console.error("Error fetching chat click:", error);
          if (isMounted) {
            setLoadingChatClicks(false); // End loading if there was an error
          }
        }
      }
    };

    fetchConversations();
    fetchLeads();
    fetchPageViews();
    fetchChatClicks();

    return () => {
      isMounted = false; // Clean up function setting flag to false
    };
  }, [userInfos]); // Dependencies array

  const handleTokenExpModalConfirm = () => {
    setTokenExpModalOpen(false);
    signOut();
    history.push("/auth");
  };

  if (loadingConversations || loadingLeads || loadingPageViews || loadingChatClicks) {
    // Return a loading spinner if we are in the loading state
    return <LoadingSpinner />;
  }

  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30);
  const timeFilteredConversations = conversations.filter(conversation => new Date(conversation.timestamp) >= thirtyDaysAgo);
  const timeFilteredLeads = leads.filter(lead => new Date(lead.date) >= thirtyDaysAgo);
  const timeFilteredPageViews = pageViews.filter(pageView => new Date(pageView.date) >= thirtyDaysAgo);
  const timeFilteredChatClicks = chatClicks.filter(chatClick => new Date(chatClick.date) >= thirtyDaysAgo);

  const timeFilteredConversionRate = (timeFilteredLeads.length / timeFilteredConversations.length * 100).toFixed(2);
  const conversionRate = (leads.length / conversations.length * 100).toFixed(2);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <div className="stats-container">
        <Text mb="20px">{t("dashboard.last_thirty_days")}</Text>

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            name={t("dashboard.page_views")}
            value={timeFilteredPageViews.length}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            name={t("dashboard.opened_chats")}
            value={timeFilteredChatClicks.length}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            // growth="+23%"
            name={t("dashboard.conversations")}
            value={timeFilteredConversations.length}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            name={t("dashboard.conversions")}
            value={timeFilteredLeads.length}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            name={t("dashboard.conversion_rate")}
            value={`${timeFilteredConversionRate} %`}
          />
        </SimpleGrid>
        
        <Text mb="20px">Total</Text>

        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            // growth="+23%"
            name={t("dashboard.conversations")}
            value={conversations.length}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            name={t("dashboard.conversions")}
            value={leads.length}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
                }
              />
            }
            name={t("dashboard.conversion_rate")}
            value={`${conversionRate} %`}
          />
        </SimpleGrid>

        {/* <Text mb="20px">{t("dashboard.stats_features")}</Text>

        <SimpleGrid 
          style={{ filter: 'blur(3px)' }}
          columns={{ base: 1, md: 2, xl: 2 }}
          gap="20px" 
          mb="20px"
        >
          <TotalSpent />
          <ComplexTable
            columnsData={columnsDataComplex}
            tableData={tableDataComplex}
          />
        </SimpleGrid> */}
      </div>
      <ExpiredTokenModal isOpen={tokenExpModalOpen} onClose={handleTokenExpModalConfirm} />
    </Box>
  );
}
