import React, { useState, useEffect } from 'react';
import { AuthContext } from "contexts/AuthContext";
import { axiosInstance } from "api/apiClient";
import { useTranslation } from 'react-i18next';
import { Box, Button, Textarea, Heading, VStack, HStack, FormControl, FormLabel, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/react";
import LoadingSpinner from '../../../components/spinner/spinner';
import { useHistory, Prompt } from 'react-router-dom';
import ExpiredTokenModal from 'components/expiredTokenModal/expiredTokenModal';

const ChatbotSettings = () => {
  const { userInfos, signOut } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [assistants, setAssistants] = useState([]);
  const [scrapingDocs, setScrapingDocs] = useState({});
  const [loadingDoc, setLoadingDoc] = useState(true);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [tokenExpModalOpen, setTokenExpModalOpen] = useState(false);

  useEffect(() => {
    if (!userInfos || !userInfos.client_id) {
      signOut();
      history.push("/auth");
      return;
    }

    const fetchDocumentation = async () => {
      try {
        const responseAssistants = await axiosInstance.get(`assistant/get_client_assistants?client_id=${userInfos.client_id}`);
        setAssistants(responseAssistants.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setTokenExpModalOpen(true);
        } else {
          console.error("Error fetching documentation:", error);
        }
      } finally {
        setLoadingDoc(false);
      }
    };

    fetchDocumentation();

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, [userInfos, signOut, history]);

  useEffect(() => {
    const fetchScrapingDocsForAssistants = async () => {
      const newScrapingDocs = {};
      await Promise.all(assistants.map(async (assistant) => {
        const doc = await fetchScrapingDoc(assistant.assistant_id);
        if (doc) {
          newScrapingDocs[assistant.assistant_id] = doc;
        }
      }));
      setScrapingDocs(newScrapingDocs);
    };

    if (assistants.length > 0) {
      fetchScrapingDocsForAssistants();
    }
  }, [assistants]);

  const fetchScrapingDoc = async (assistant_id) => {
    try {
      const response = await axiosInstance.get(`firestore/get_scraping_document?document_id=${assistant_id}&request_url=dashboard&specific_url=dashboard`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setTokenExpModalOpen(true);
      } else {
        console.error("Error fetching scraping document:", error);
      }
      return null;
    }
  };

  const handleBeforeUnload = (e) => {
    if (unsavedChanges) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  const handleTokenExpModalConfirm = () => {
    setTokenExpModalOpen(false);
    signOut();
    history.push("/auth");
  };

  const handleScrapingChange = (assistantId, key, value, nestedIndex = null) => {
    const newScrapingDocs = { ...scrapingDocs };
    if (nestedIndex !== null) {
      newScrapingDocs[assistantId][key][nestedIndex] = value;
    } else {
      newScrapingDocs[assistantId][key] = value;
    }
    setScrapingDocs(newScrapingDocs);
    setUnsavedChanges(true);
  };

  const addUrl = (assistantId) => {
    const newScrapingDocs = { ...scrapingDocs };
    const newUrl = { url: '', language: '', init_question: '', popup_one: '', popup_two: '' };
    newScrapingDocs[assistantId].urls.push(newUrl);
    setScrapingDocs(newScrapingDocs);
    setUnsavedChanges(true);
  };

  const removeUrl = (assistantId, urlIndex) => {
    const newScrapingDocs = { ...scrapingDocs };
    newScrapingDocs[assistantId].urls.splice(urlIndex, 1);
    setScrapingDocs(newScrapingDocs);
    setUnsavedChanges(true);
  };

  const saveScrapingDocumentation = async (assistantId) => {
    try {
      const scrapingDoc = scrapingDocs[assistantId];
      await axiosInstance.post(`firestore/set_scraping_document?client_id=${userInfos.client_id}&assistant_id=${assistantId}`,
        scrapingDoc, {
        headers: {'Content-Type': 'application/json'}
      });
      alert(t('knowledge_base.documentation_saved_alert'));
      setUnsavedChanges(false);
    } catch (error) {
      console.error('Error saving scraping documentation:', error);
    }
  };

  if (loadingDoc) {
    return <LoadingSpinner />;
  }

  return (
    <Box height="100vh" pt={{ base: "180px", md: "80px", xl: "80px" }} overflowY="auto" p="20px" fontFamily="Arial, sans-serif">
      <Prompt
        when={unsavedChanges}
        message={t("training.unsaved_changes_alert")}
      />
      <Accordion allowMultiple mb="40px">
        {assistants.map((assistant, i) => {
          const scrapingDoc = scrapingDocs[assistant.assistant_id] || {};
          return (
            <AccordionItem key={assistant.assistant_id} border="1px solid #ccc" borderRadius="8px" mb="10px">
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading as="h2" size="md">{assistant.name}</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <FormControl id={`company_name_${i}`} mb="10px">
                  <FormLabel>{t("general_settings.company_name")}</FormLabel>
                  <Textarea
                    value={scrapingDoc.company_name || ''}
                    onChange={(e) => handleScrapingChange(assistant.assistant_id, "company_name", e.target.value)}
                    resize="none"
                    minHeight="40px"
                  />
                </FormControl>

                <FormControl id={`height_${i}`} mb="10px">
                  <FormLabel>{t("chatbot_settings.height")}</FormLabel>
                  <Textarea
                    value={scrapingDoc.height || ''}
                    onChange={(e) => handleScrapingChange(assistant.assistant_id, "height", e.target.value)}
                    resize="none"
                    minHeight="40px"
                  />
                </FormControl>

                <FormControl id={`use_lang_tag_${i}`} mb="10px">
                  <FormLabel>{t("chatbot_settings.use_lang_tag")}</FormLabel>
                  <input
                    type="checkbox"
                    checked={scrapingDoc.use_lang_tag || false}
                    onChange={(e) => handleScrapingChange(assistant.assistant_id, "use_lang_tag", e.target.checked)}
                  />
                </FormControl>

                <FormControl id={`mobile_popup_${i}`} mb="10px">
                  <FormLabel>{t("chatbot_settings.mobile_popup")}</FormLabel>
                  <input
                    type="checkbox"
                    checked={scrapingDoc.mobile_popup || false}
                    onChange={(e) => handleScrapingChange(assistant.assistant_id, "mobile_popup", e.target.checked)}
                  />
                </FormControl>

                <FormControl id={`popup_once_${i}`} mb="10px">
                  <FormLabel>{t("chatbot_settings.popup_once")}</FormLabel>
                  <input
                    type="checkbox"
                    checked={scrapingDoc.popup_once || false}
                    onChange={(e) => handleScrapingChange(assistant.assistant_id, "popup_once", e.target.checked)}
                  />
                </FormControl>

                {/* Chatbot Names */}
                <Box mb="10px">
                  <Heading as="h4" size="sm">{t("chatbot_settings.chatbot_names")}</Heading>
                  <FormControl id={`chatbot_names_english_${i}`} mb="10px">
                    <FormLabel>{t("english")}</FormLabel>
                    <Textarea
                      value={scrapingDoc.chatbot_names?.english || ''}
                      onChange={(e) => handleScrapingChange(assistant.assistant_id, "chatbot_names", { ...scrapingDoc.chatbot_names, english: e.target.value })}
                      resize="none"
                      minHeight="40px"
                    />
                  </FormControl>

                  <FormControl id={`chatbot_names_french_${i}`} mb="10px">
                    <FormLabel>{t("french")}</FormLabel>
                    <Textarea
                      value={scrapingDoc.chatbot_names?.french || ''}
                      onChange={(e) => handleScrapingChange(assistant.assistant_id, "chatbot_names", { ...scrapingDoc.chatbot_names, french: e.target.value })}
                      resize="none"
                      minHeight="40px"
                    />
                  </FormControl>
                </Box>

                {/* Slogans */}
                <Box mb="10px">
                  <Heading as="h4" size="sm">Slogans</Heading>
                  <FormControl id={`slogans_english_${i}`} mb="10px">
                    <FormLabel>{t("english")}</FormLabel>
                    <Textarea
                      value={scrapingDoc.slogans?.english || ''}
                      onChange={(e) => handleScrapingChange(assistant.assistant_id, "slogans", { ...scrapingDoc.slogans, english: e.target.value })}
                      resize="none"
                      minHeight="40px"
                    />
                  </FormControl>

                  <FormControl id={`slogans_french_${i}`} mb="10px">
                    <FormLabel>{t("french")}</FormLabel>
                    <Textarea
                      value={scrapingDoc.slogans?.french || ''}
                      onChange={(e) => handleScrapingChange(assistant.assistant_id, "slogans", { ...scrapingDoc.slogans, french: e.target.value })}
                      resize="none"
                      minHeight="40px"
                    />
                  </FormControl>
                </Box>

                {/* Introductions */}
                <Box mb="10px">
                  <Heading as="h4" size="sm">Introductions</Heading>
                  <FormControl id={`introductions_english_${i}`} mb="10px">
                    <FormLabel>{t("english")}</FormLabel>
                    <Textarea
                      value={scrapingDoc.introductions?.english || ''}
                      onChange={(e) => handleScrapingChange(assistant.assistant_id, "introductions", { ...scrapingDoc.introductions, english: e.target.value })}
                      resize="none"
                      minHeight="40px"
                    />
                  </FormControl>

                  <FormControl id={`introductions_french_${i}`} mb="10px">
                    <FormLabel>{t("french")}</FormLabel>
                    <Textarea
                      value={scrapingDoc.introductions?.french || ''}
                      onChange={(e) => handleScrapingChange(assistant.assistant_id, "introductions", { ...scrapingDoc.introductions, french: e.target.value })}
                      resize="none"
                      minHeight="40px"
                    />
                  </FormControl>
                </Box>

                {/* URLs */}
                <Box mb="10px">
                  <Heading as="h4" size="sm">URLs</Heading>
                  {scrapingDoc.urls && scrapingDoc.urls.map((url, j) => (
                    <Box key={`url_${i}_${j}`} mb="10px" border="1px solid #ccc" p="10px" borderRadius="8px">
                      <FormControl id={`url_${i}_${j}`} mb="10px">
                        <FormLabel>URL</FormLabel>
                        <Textarea
                          value={url.url || ''}
                          onChange={(e) => {
                            const newUrls = scrapingDoc.urls.map((u, index) => index === j ? { ...u, url: e.target.value } : u);
                            handleScrapingChange(assistant.assistant_id, "urls", newUrls);
                          }}
                          resize="none"
                          minHeight="40px"
                        />
                      </FormControl>

                      <FormControl id={`language_${i}_${j}`} mb="10px">
                        <FormLabel>{t("general_settings.language")}</FormLabel>
                        <Textarea
                          value={url.language || ''}
                          onChange={(e) => {
                            const newUrls = scrapingDoc.urls.map((u, index) => index === j ? { ...u, language: e.target.value } : u);
                            handleScrapingChange(assistant.assistant_id, "urls", newUrls);
                          }}
                          resize="none"
                          minHeight="40px"
                        />
                      </FormControl>

                      <FormControl id={`init_question_${i}_${j}`} mb="10px">
                        <FormLabel>{t("chatbot_settings.init_question")}</FormLabel>
                        <Textarea
                          value={url.init_question || ''}
                          onChange={(e) => {
                            const newUrls = scrapingDoc.urls.map((u, index) => index === j ? { ...u, init_question: e.target.value } : u);
                            handleScrapingChange(assistant.assistant_id, "urls", newUrls);
                          }}
                          resize="none"
                          minHeight="40px"
                        />
                      </FormControl>

                      <FormControl id={`popup_one_${i}_${j}`} mb="10px">
                        <FormLabel>{t("chatbot_settings.popup_one")}</FormLabel>
                        <Textarea
                          value={url.popup_one || ''}
                          onChange={(e) => {
                            const newUrls = scrapingDoc.urls.map((u, index) => index === j ? { ...u, popup_one: e.target.value } : u);
                            handleScrapingChange(assistant.assistant_id, "urls", newUrls);
                          }}
                          resize="none"
                          minHeight="40px"
                        />
                      </FormControl>

                      <FormControl id={`popup_two_${i}_${j}`} mb="10px">
                        <FormLabel>{t("chatbot_settings.popup_two")}</FormLabel>
                        <Textarea
                          value={url.popup_two || ''}
                          onChange={(e) => {
                            const newUrls = scrapingDoc.urls.map((u, index) => index === j ? { ...u, popup_two: e.target.value } : u);
                            handleScrapingChange(assistant.assistant_id, "urls", newUrls);
                          }}
                          resize="none"
                          minHeight="40px"
                        />
                      </FormControl>

                      <Button colorScheme="red" onClick={() => removeUrl(assistant.assistant_id, j)}>{t("chatbot_settings.remove_url")}</Button>
                    </Box>
                  ))}
                  <Button colorScheme="blue" onClick={() => addUrl(assistant.assistant_id)}>{t("chatbot_settings.add_url")}</Button>
                </Box>

                <Button type="submit" colorScheme="blue" mt="20px" mb="40px" onClick={() => saveScrapingDocumentation(assistant.assistant_id)}>{t('save_button')}</Button>
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
      <ExpiredTokenModal isOpen={tokenExpModalOpen} onClose={handleTokenExpModalConfirm} />
    </Box>
  );
};

export default ChatbotSettings;