import axios from 'axios';
import backendPaths from '../config/apiPath';

const axiosInstance = axios.create({
  baseURL: backendPaths.production,
});

// Function to set the Authorization token
const setAuthToken = (token) => {
  if (token) {
    // Store token in localStorage
    localStorage.setItem("jwtToken", token);
    // Set token in the Axios instance headers
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // Remove token from localStorage
    localStorage.removeItem("jwtToken");
    // Remove token from Axios instance headers
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

// Retrieve token from localStorage and set it in Axios instance headers if it exists
const token = localStorage.getItem("jwtToken");
if (token) {
  setAuthToken(token);
}

export { axiosInstance, setAuthToken };
