import React from 'react'
import { Icon, Button } from "@chakra-ui/react";
import { MdInfo } from "react-icons/md";
import MessageItem from './MessageItem';
import "../Conversation.css";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "api/apiClient";

export default function ConversationItem(props) {
    const { displayed } = props;
    const { t } = useTranslation();

    const saveAsExample = async (clientId, assistantId, threadId) => {
        try {
            await axiosInstance.post(
                `examples/save_conv_to_examples?client_id=${clientId}&assistant_id=${assistantId}&thread_id=${threadId}`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            alert(t("training.example_saved_alert"));
            } catch (error) {
                console.error('Error saving example:', error);
            }
    };
    
    if (displayed.source) {
        if (displayed.source.url == "no source url found") {
            displayed.source.url = "sandbox";
        }
    }

    return (
        <div className='selected-conversation-container'>
            <div className='selected-conversation-infos'>
                <Icon as={MdInfo} width='20px' height='20px' color='inherit' float='right'/>
                <Button
                    colorScheme="blue"
                    float='right'
                    mt="30px"
                    onClick={() => saveAsExample(displayed.client_id, displayed.assistant_id, displayed.thread_id)}
                >
                    {t("training.save_as_example")}
                </Button>
                <p>ID : {displayed.thread_id}</p>
                <p>Assistant : {displayed.assistant_id}</p>
                <p>{t("conversations.device")} : {displayed.source ? displayed.source.device : 'N/A'}</p>
                <p>Source : {displayed.source ? displayed.source.url : 'N/A'}</p>
            </div>
        
            <div className='selected-conversation-content'>
                {displayed.messages.map(message => (
                    <div key={message.message_id}>
                        <MessageItem bubble={message}/>
                    </div>
                ))}
            </div>
        </div>
    )
}
