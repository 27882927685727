// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root {
    height: 100%;
    overflow: hidden;
}

.general-settings-form {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin: 2em;
}

.general-settings-form div {
    display: flex;
    flex-direction: column;
}

.general-settings-form input {
    border-radius: 1em;
    height: 3em;
    padding: 1em;
}

.general-settings-form label {
    margin: 1em;
}

.general-settings-form select {
    height: 3em;
}

.general-settings-form button {
    margin-left: 3em;
    height: 3em;
    width: 6em;
    border-radius: 1em;
    color: white;
    background-color: rgb(72, 72, 206);
}`, "",{"version":3,"sources":["webpack://./src/views/admin/generalSettings/GeneralSettings.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,kCAAkC;AACtC","sourcesContent":["html, body, #root {\n    height: 100%;\n    overflow: hidden;\n}\n\n.general-settings-form {\n    display: flex;\n    flex-direction: column;\n    gap: 2em;\n    margin: 2em;\n}\n\n.general-settings-form div {\n    display: flex;\n    flex-direction: column;\n}\n\n.general-settings-form input {\n    border-radius: 1em;\n    height: 3em;\n    padding: 1em;\n}\n\n.general-settings-form label {\n    margin: 1em;\n}\n\n.general-settings-form select {\n    height: 3em;\n}\n\n.general-settings-form button {\n    margin-left: 3em;\n    height: 3em;\n    width: 6em;\n    border-radius: 1em;\n    color: white;\n    background-color: rgb(72, 72, 206);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
