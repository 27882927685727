import React, { useState, useEffect } from 'react';
import { Box, Select } from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import { useHistory } from 'react-router-dom';
import { axiosInstance } from "api/apiClient";
import LoadingSpinner from '../../../components/spinner/spinner';
import ExpiredTokenModal from 'components/expiredTokenModal/expiredTokenModal';

export default function SandBox() {
  const { userInfos, signOut } = React.useContext(AuthContext);
  const history = useHistory();
  const token = localStorage.getItem("jwtToken");

  const [clientWebsiteURL, setClientWebsiteURL] = useState("");
  const [assistants, setAssistants] = useState([]);
  const [loadingDoc, setLoadingDoc] = useState(true);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [tokenExpModalOpen, setTokenExpModalOpen] = useState(false);

  useEffect(() => {
    if (!userInfos || !userInfos.client_id) {
      signOut();
      history.push("/auth");
      return;
    }

    let isMounted = true;

    const fetchAssistants = async () => {
      try {
        const response = await axiosInstance.get(`assistant/get_client_assistants?client_id=${userInfos.client_id}`);
        if (isMounted) {
          setAssistants(response.data);
          setLoadingDoc(false);
          if (response.data.length > 0) {
            const defaultAssistant = response.data[0];
            setSelectedAssistant(defaultAssistant);
            setClientWebsiteURL(`https://capture-wetiwctgqq-nn.a.run.app/v1/sandbox/?vf_id=${defaultAssistant.vf_id}&token=${token}`);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setTokenExpModalOpen(true);
          setLoadingDoc(false);
        } else {
          console.error("Error fetching assistants:", error);
          if (isMounted) {
            setLoadingDoc(false);
          }
        }
      }
    };

    fetchAssistants();

    return () => {
      isMounted = false;
    };
  }, [userInfos]);

  const handleAssistantChange = (event) => {
    const selectedId = event.target.value;
    const selectedAssistant = assistants.find(assistant => assistant.assistant_id === selectedId);
    if (selectedAssistant) {
      setSelectedAssistant(selectedAssistant);
      setClientWebsiteURL(`https://capture-wetiwctgqq-nn.a.run.app/v1/sandbox/?vf_id=${selectedAssistant.vf_id}&token=${token}`);
    }
  };

  const handleTokenExpModalConfirm = () => {
    setTokenExpModalOpen(false);
    signOut();
    history.push("/auth");
  };

  if (loadingDoc) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      pt={{ base: "180px", md: "80px", xl: "80px" }}
      px={0}
      height="calc(100vh - 80px)"
      position="relative"
    >
      <Select
        maxWidth="30%"
        onChange={handleAssistantChange}
        value={selectedAssistant ? selectedAssistant.assistant_id : ''}
        mb={4}
      >
        {assistants.map(assistant => (
          <option key={assistant.assistant_id} value={assistant.assistant_id}>{assistant.name}</option>
        ))}
      </Select>

      <div 
        className="sandbox-main-content" 
        style={{ 
          position: 'relative', 
          height: "90%", 
          top: "20px", 
          left: 0, 
          right: 0, 
          bottom: 0
        }}
      >
        <iframe
          key={clientWebsiteURL}
          title="client_website"
          src={clientWebsiteURL}
          style={{ 
            width: "100%", 
            height: "100%",
            border: "none",
          }}
          allowFullScreen
        />
      </div>
      <ExpiredTokenModal isOpen={tokenExpModalOpen} onClose={handleTokenExpModalConfirm} />
    </Box>
  );
};
