import React, { useState, useEffect } from 'react';
import { AuthContext } from "contexts/AuthContext";
import { axiosInstance } from "api/apiClient";
import { useTranslation } from 'react-i18next';
import { Box, Button, Textarea, Heading, VStack, HStack, FormControl, FormLabel, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/react";
import LoadingSpinner from '../../../components/spinner/spinner';
import { useHistory, Prompt } from 'react-router-dom';
import ExpiredTokenModal from 'components/expiredTokenModal/expiredTokenModal';

const KnowledgeBase = () => {
  const { userInfos, signOut } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [assistants, setAssistants] = useState([]);
  const [loadingDoc, setLoadingDoc] = useState(true);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [tokenExpModalOpen, setTokenExpModalOpen] = useState(false);

  useEffect(() => {
    if (!userInfos || !userInfos.client_id) {
      signOut();
      history.push("/auth");
      return;
    }

    let isMounted = true;

    const fetchDocumentation = async () => {
        try {
          const response = await axiosInstance.get(`assistant/get_client_assistants?client_id=${userInfos.client_id}`);
          if (isMounted) {
            setAssistants(response.data);
            setLoadingDoc(false);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            setTokenExpModalOpen(true);
            setLoadingDoc(false);
          } else {
            console.error("Error fetching documentation:", error);
            if (isMounted) {
              setLoadingDoc(false);
            }
          }
        }
    };

    fetchDocumentation();
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
        isMounted = false;
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, [userInfos]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const handleTokenExpModalConfirm = () => {
    setTokenExpModalOpen(false);
    signOut();
    history.push("/auth");
  };

  const handleBeforeUnload = (e) => {
    if (unsavedChanges) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  const handleChange = (assistantIndex, section, key, value, index = null, nestedIndex = null, nestedKey = null) => {
    const newAssistants = [...assistants];
    const documentation = newAssistants[assistantIndex].prompt.documentation;

    if (index !== null && (section === 'contact' || section === 'offers')) {
      if (nestedIndex !== null && nestedKey) {
        documentation[section][index][key][nestedIndex][nestedKey] = value;
      } else if (nestedIndex !== null) {
        documentation[section][index][key][nestedIndex] = value;
      } else {
        documentation[section][index][key] = value;
      }
    } else if (index !== null) {
      if (key) {
        documentation[section][key][index] = value;
      } else {
        documentation[section][index] = value;
      }
    } else {
      documentation[section][key] = value;
    }

    setAssistants(newAssistants);
    setUnsavedChanges(true);
  };

  const addField = (assistantIndex, section, key, index = null) => {
    const newAssistants = [...assistants];
    const documentation = newAssistants[assistantIndex].prompt.documentation;

    if (index !== null) {
      if (key) {
        if (!documentation[section][index][key]) documentation[section][index][key] = [];
        documentation[section][index][key].push("");
      } else if (section === 'contact') {
        documentation[section].push({
          email_addresses: [""],
          business_address: "",
          phone_numbers: [""],
          social_media_links: [""],
          operating_hours: "",
          additional_fields: [""],
        });
      } else {
        documentation[section].push({
          name: "",
          description: "",
          pricing: "",
          additional_fields: [""],
        });
      }
    } else {
      if (key) {
        documentation[section][key].push("");
      } else if (section === 'contact') {
        documentation[section].push({
          email_addresses: [""],
          business_address: "",
          phone_numbers: [""],
          social_media_links: [""],
          operating_hours: "",
          additional_fields: [""],
        });
      } else if (section === 'offers') {
        documentation[section].push({
          name: "",
          description: "",
          pricing: "",
          additional_fields: [""],
        });
      } else {
        documentation[section].push("");
      }
    }

    setAssistants(newAssistants);
    setUnsavedChanges(true);
  };

  const removeField = (assistantIndex, section, key, index, nestedIndex = null) => {
    const newAssistants = [...assistants];
    const documentation = newAssistants[assistantIndex].prompt.documentation;

    if (nestedIndex !== null) {
      documentation[section][index][key].splice(nestedIndex, 1);
    } else if (key) {
      documentation[section][key].splice(index, 1);
    } else {
      documentation[section].splice(index, 1);
    }

    setAssistants(newAssistants);
    setUnsavedChanges(true);
  };

  const saveDocumentation = async (assistant_id, documentation) => {
    try {
      await axiosInstance.post(
        `assistant/update_doc?client_id=${userInfos.client_id}&assistant_id=${assistant_id}`,
        documentation,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      alert(t("knowledge_base.documentation_saved_alert"));
      setUnsavedChanges(false);
    } catch (error) {
      console.error('Error saving documentation:', error);
    }
  };

  if (loadingDoc) {
    return <LoadingSpinner />;
  }

  return (
    <Box height="100vh" pt={{ base: "180px", md: "80px", xl: "80px" }} overflowY="auto" p="20px" fontFamily="Arial, sans-serif">
      <Prompt
        when={unsavedChanges}
        message={t("training.unsaved_changes_alert")}
      />
      <Accordion allowMultiple mb="40px">
        {assistants.map((assistant, i) => (
          <AccordionItem key={assistant.assistant_id} border="1px solid #ccc" borderRadius="8px" mb="10px">
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading as="h2" size="md">{assistant.name}</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              {!assistant.prompt ? (
                <Box
                  p="20px"
                  bg="red.100"
                  color="red.700"
                  borderRadius="8px"
                  mb="20px"
                >
                  {t('knowledge_base.null_prompt')}
                </Box>
              ) : (
                <Box mb="20px">
                  {/* About Section */}
                  <Box mb="20px">
                    <Heading as="h1" size="md" mb="10px">{t('knowledge_base.about')}</Heading>
                    
                    <FormControl id="company_overview" mb="10px">
                      <FormLabel>{t('knowledge_base.company_overview')}</FormLabel>
                      <Textarea 
                        value={assistant.prompt.documentation.about.company_overview} 
                        onChange={(e) => handleChange(i, "about", "company_overview", e.target.value)} 
                        resize="none"
                        minHeight="40px"
                        width="100%"
                        overflow="hidden"
                        spellCheck={false}
                        onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                        onBlur={(e) => e.target.style.height = '40px'}
                      />
                    </FormControl>
                    
                    <FormControl id="mission" mb="10px">
                      <FormLabel>Mission</FormLabel>
                      <Textarea 
                        value={assistant.prompt.documentation.about.mission} 
                        onChange={(e) => handleChange(i, "about", "mission", e.target.value)} 
                        resize="none"
                        minHeight="40px"
                        width="100%"
                        overflow="hidden"
                        spellCheck={false}
                        onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                        onBlur={(e) => e.target.style.height = '40px'}
                      />
                    </FormControl>
                    
                    <FormControl id="history" mb="10px">
                      <FormLabel>{t('knowledge_base.history')}</FormLabel>
                      <Textarea 
                        value={assistant.prompt.documentation.about.history} 
                        onChange={(e) => handleChange(i, "about", "history", e.target.value)} 
                        resize="none"
                        minHeight="40px"
                        width="100%"
                        overflow="hidden"
                        spellCheck={false}
                        onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                        onBlur={(e) => e.target.style.height = '40px'}
                      />
                    </FormControl>
                    
                    <Box mb="20px">
                      <Heading as="h2" size="sm" mb="10px" fontWeight="normal">{t('knowledge_base.team')}</Heading>
                      {assistant.prompt.documentation.about.team.map((member, idx) => (
                        <HStack key={`team_${assistant.assistant_id}_${idx}`} spacing="10px" mb="10px">
                          <Textarea placeholder={t('Name')} value={member.name || ""} onChange={(e) => handleChange(i, "about", "team", { ...member, name: e.target.value }, idx)} minHeight="40px" spellCheck={false} resize="none" />
                          <Textarea placeholder={t('Position')} value={member.position || ""} onChange={(e) => handleChange(i, "about", "team", { ...member, position: e.target.value }, idx)} minHeight="40px" spellCheck={false} resize="none" />
                          <Button colorScheme="red" onClick={() => removeField(i, "about", "team", idx)}>-</Button>
                        </HStack>
                      ))}
                      <Button colorScheme="blue" onClick={() => addField(i, "about", "team")}>+</Button>
                    </Box>
                    
                    <Box mb="20px">
                      <Heading as="h2" size="sm" mb="10px" fontWeight="normal">{t('knowledge_base.additional_fields')}</Heading>
                      {assistant.prompt.documentation.about.additional_fields.map((field, idx) => (
                        <HStack key={`about_additional_${assistant.assistant_id}_${idx}`} spacing="10px" mb="10px">
                          <Textarea 
                            value={field} 
                            onChange={(e) => handleChange(i, "about", "additional_fields", e.target.value, idx)}
                            resize="none"
                            minHeight="40px"
                            width="100%"
                            overflow="hidden"
                            spellCheck={false}
                            onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                            onBlur={(e) => e.target.style.height = '40px'}
                          />
                          <Button colorScheme="red" onClick={() => removeField(i, "about", "additional_fields", idx)}>-</Button>
                        </HStack>
                      ))}
                      <Button colorScheme="blue" onClick={() => addField(i, "about", "additional_fields")}>+</Button>
                    </Box>
                  </Box>

                  {/* Contact Section */}
                  <Box mb="20px">
                    <Heading as="h1" size="md" mb="10px">Contacts</Heading>
                    {assistant.prompt.documentation.contact.map((contact, idx) => (
                      <Box key={`contact_${assistant.assistant_id}_${idx}`} mb="20px" border="1px solid #ccc" padding="15px" borderRadius="8px">
                        <FormControl id={`business_address_${assistant.assistant_id}_${idx}`} mb="10px">
                          <FormLabel>{t('knowledge_base.business_address')}</FormLabel>
                          <Textarea value={contact.business_address} onChange={(e) => handleChange(i, "contact", "business_address", e.target.value, idx)} minHeight="40px" spellCheck={false} resize="none" />
                        </FormControl>

                        <FormControl id={`operating_hours_${assistant.assistant_id}_${idx}`} mb="10px">
                          <FormLabel>{t('knowledge_base.operating_hours')}</FormLabel>
                          <Textarea value={contact.operating_hours} onChange={(e) => handleChange(i, "contact", "operating_hours", e.target.value, idx)} minHeight="40px" spellCheck={false} resize="none" />
                        </FormControl>

                        <Heading as="h2" size="sm" mb="10px" fontWeight="normal">{t('knowledge_base.email_address')}</Heading>
                        {contact.email_addresses.map((email, nestedIdx) => (
                          <HStack key={`email_address_${assistant.assistant_id}_${nestedIdx}`} spacing="10px" mb="10px">
                            <Textarea value={email} onChange={(e) => handleChange(i, "contact", "email_addresses", e.target.value, idx, nestedIdx)} minHeight="40px" spellCheck={false} resize="none" />
                            <Button colorScheme="red" onClick={() => removeField(i, "contact", "email_addresses", idx, nestedIdx)}>-</Button>
                          </HStack>
                        ))}
                        <Button colorScheme="blue" mb="20px" onClick={() => addField(i, "contact", "email_addresses", idx)}>+</Button>

                        <Heading as="h2" size="sm" mb="10px" fontWeight="normal">{t('knowledge_base.phone_numbers')}</Heading>
                        {contact.phone_numbers.map((phone, nestedIdx) => (
                          <HStack key={`phone_numbers_${assistant.assistant_id}_${nestedIdx}`} spacing="10px" mb="10px">
                            <Textarea value={phone} onChange={(e) => handleChange(i, "contact", "phone_numbers", e.target.value, idx, nestedIdx)} minHeight="40px" spellCheck={false} resize="none" />
                            <Button colorScheme="red" onClick={() => removeField(i, "contact", "phone_numbers", idx, nestedIdx)}>-</Button>
                          </HStack>
                        ))}
                        <Button colorScheme="blue" mb="20px" onClick={() => addField(i, "contact", "phone_numbers", idx)}>+</Button>

                        <Heading as="h2" size="sm" mb="10px" fontWeight="normal">{t('knowledge_base.social_media_links')}</Heading>
                        {contact.social_media_links.map((link, nestedIdx) => (
                          <HStack key={`social_media_links_${assistant.assistant_id}_${nestedIdx}`} spacing="10px" mb="10px">
                            <Textarea value={link} onChange={(e) => handleChange(i, "contact", "social_media_links", e.target.value, idx, nestedIdx)} minHeight="40px" spellCheck={false} resize="none" />
                            <Button colorScheme="red" onClick={() => removeField(i, "contact", "social_media_links", idx, nestedIdx)}>-</Button>
                          </HStack>
                        ))}
                        <Button colorScheme="blue" mb="20px" onClick={() => addField(i, "contact", "social_media_links", idx)}>+</Button>

                        <Heading as="h2" size="sm" mb="10px" fontWeight="normal">{t('knowledge_base.additional_fields')}</Heading>
                        {contact.additional_fields.map((field, nestedIdx) => (
                          <HStack key={`contact_additional_${assistant.assistant_id}_${nestedIdx}`} spacing="10px" mb="10px">
                            <Textarea 
                              value={field} 
                              onChange={(e) => handleChange(i, "contact", "additional_fields", e.target.value, idx, nestedIdx)} 
                              resize="none"
                              minHeight="40px"
                              width="100%"
                              overflow="hidden"
                              spellCheck={false}
                              onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                              onBlur={(e) => e.target.style.height = '40px'}
                            />
                            <Button colorScheme="red" onClick={() => removeField(i, "contact", "additional_fields", idx, nestedIdx)}>-</Button>
                          </HStack>
                        ))}
                        <Button colorScheme="blue" onClick={() => addField(i, "contact", "additional_fields", idx)}>+</Button>

                        <VStack spacing="10px" mt="10px">
                          <Button colorScheme="red" onClick={() => removeField(i, "contact", "", idx)}  ml="20px">{t('knowledge_base.remove_contact')}</Button>
                        </VStack>
                      </Box>
                    ))}
                    <Button colorScheme="blue" onClick={() => addField(i, "contact", null, null)}>{t('knowledge_base.add_contact')}</Button>
                  </Box>

                  {/* Offers Section */}
                  <Box mb="20px">
                    <Heading as="h1" size="md" mb="10px">{t('knowledge_base.offers')}</Heading>
                    {assistant.prompt.documentation.offers.map((offers, idx) => (
                      <Box key={`offer_${assistant.assistant_id}_${idx}`} mb="20px" border="1px solid #ccc" padding="15px" borderRadius="8px">
                        <FormControl id={`name_${assistant.assistant_id}_${idx}`} mb="10px">
                          <FormLabel>{t('knowledge_base.name')}</FormLabel>
                          <Textarea value={offers.name} onChange={(e) => handleChange(i, "offers", "name", e.target.value, idx)} minHeight="40px" spellCheck={false} resize="none" />
                        </FormControl>

                        <FormControl id={`description_${assistant.assistant_id}_${idx}`} mb="10px">
                          <FormLabel>Description</FormLabel>
                          <Textarea 
                            value={offers.description} 
                            onChange={(e) => handleChange(i, "offers", "description", e.target.value, idx)} 
                            resize="none"
                            minHeight="40px"
                            width="100%"
                            overflow="hidden"
                            spellCheck={false}
                            onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                            onBlur={(e) => e.target.style.height = '40px'}
                          />
                        </FormControl>

                        <FormControl id={`pricing_${assistant.assistant_id}_${idx}`} mb="10px">
                          <FormLabel>{t('knowledge_base.pricing')}</FormLabel>
                          <Textarea 
                            value={offers.pricing} 
                            onChange={(e) => handleChange(i, "offers", "pricing", e.target.value, idx)} 
                            resize="none"
                            minHeight="40px"
                            width="100%"
                            overflow="hidden"
                            spellCheck={false}
                            onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                            onBlur={(e) => e.target.style.height = '40px'}
                          />
                        </FormControl>

                        <Heading as="h2" size="sm" mb="10px" fontWeight="normal">{t('knowledge_base.additional_fields')}</Heading>
                        {offers.additional_fields.map((field, nestedIdx) => (
                          <HStack key={`offers_additional_${assistant.assistant_id}_${nestedIdx}`} spacing="10px" mb="10px">
                            <Textarea 
                              value={field} 
                              onChange={(e) => handleChange(i, "offers", "additional_fields", e.target.value, idx, nestedIdx)} 
                              resize="none"
                              minHeight="40px"
                              width="100%"
                              overflow="hidden"
                              spellCheck={false}
                              onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                              onBlur={(e) => e.target.style.height = '40px'}
                            />
                            <Button colorScheme="red" onClick={() => removeField(i, "offers", "additional_fields", idx, nestedIdx)}>-</Button>
                          </HStack>
                        ))}
                        <Button colorScheme="blue" onClick={() => addField(i, "offers", "additional_fields", idx)}>+</Button>

                        <VStack spacing="10px" mt="10px">
                          <Button colorScheme="red" onClick={() => removeField(i, "offers", "", idx)}  ml="20px">{t('knowledge_base.remove_offer')}</Button>
                        </VStack>
                      </Box>
                    ))}
                    <Button colorScheme="blue" onClick={() => addField(i, "offers", null, null)}>{t('knowledge_base.add_offer')}</Button>
                  </Box>

                  {/* Marketing Section */}
                  <Box mb="20px">
                    <Heading as="h1" size="md" mb="10px">Marketing</Heading>
                    
                    <FormControl id="value_proposition" mb="10px">
                      <FormLabel>{t('knowledge_base.value_proposition')}</FormLabel>
                      <Textarea 
                        value={assistant.prompt.documentation.marketing.value_proposition} 
                        onChange={(e) => handleChange(i, "marketing", "value_proposition", e.target.value)} 
                        resize="none"
                        minHeight="40px"
                        width="100%"
                        overflow="hidden"
                        spellCheck={false}
                        onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                        onBlur={(e) => e.target.style.height = '40px'}
                      />
                    </FormControl>
                    
                    <FormControl id="slogan" mb="10px">
                      <FormLabel>Slogan</FormLabel>
                      <Textarea value={assistant.prompt.documentation.marketing.slogan} onChange={(e) => handleChange(i, "marketing", "slogan", e.target.value)} minHeight="40px" spellCheck={false} resize="none" />
                    </FormControl>
                    
                    <FormControl id="call_to_action" mb="10px">
                      <FormLabel>{t('knowledge_base.call_to_action')}</FormLabel>
                      <Textarea value={assistant.prompt.documentation.marketing.call_to_action} onChange={(e) => handleChange(i, "marketing", "call_to_action", e.target.value)} minHeight="40px" spellCheck={false} resize="none" />
                    </FormControl>

                    <Box mb="20px">
                      <Heading as="h2" size="sm" mb="10px" fontWeight="normal">{t('knowledge_base.calendar_links')}</Heading>
                      {assistant.prompt.documentation.marketing.calendar_links.map((field, idx) => (
                        <HStack key={`marketing_calendar_link_${assistant.assistant_id}_${idx}`} spacing="10px" mb="10px">
                          <Textarea value={field} onChange={(e) => handleChange(i, "marketing", "calendar_links", e.target.value, idx)} minHeight="40px" spellCheck={false} resize="none" />
                          <Button colorScheme="red" onClick={() => removeField("marketing", "calendar_links", idx)}>-</Button>
                        </HStack>
                      ))}
                      <Button colorScheme="blue" onClick={() => addField(i, "marketing", "calendar_links")}>+</Button>
                    </Box>          
                    
                    <Box mb="20px">
                      <Heading as="h2" size="sm" mb="10px" fontWeight="normal">{t('knowledge_base.additional_fields')}</Heading>
                      {assistant.prompt.documentation.marketing.additional_fields.map((field, idx) => (
                        <HStack key={`marketing_additional_${assistant.assistant_id}_${idx}`} spacing="10px" mb="10px">
                          <Textarea 
                            value={field} 
                            onChange={(e) => handleChange(i, "marketing", "additional_fields", e.target.value, idx)} 
                            resize="none"
                            minHeight="40px"
                            width="100%"
                            overflow="hidden"
                            spellCheck={false}
                            onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                            onBlur={(e) => e.target.style.height = '40px'}
                          />
                          <Button colorScheme="red" onClick={() => removeField(i, "marketing", "additional_fields", idx)}>-</Button>
                        </HStack>
                      ))}
                      <Button colorScheme="blue" onClick={() => addField(i, "marketing", "additional_fields")}>+</Button>
                    </Box>
                  </Box>

                  {/* Sitemaps Section */}
                  <Box mb="20px">
                    <Heading as="h1" size="md" mb="10px">{t('knowledge_base.sitemaps')}</Heading>
                    {assistant.prompt.documentation.sitemaps.map((field, idx) => (
                      <HStack key={`sitemap_${assistant.assistant_id}_${idx}`} spacing="10px" mb="10px">
                        <Textarea value={field} onChange={(e) => handleChange(i, "sitemaps", null, e.target.value, idx)} minHeight="40px" spellCheck={false} resize="none" />
                        <Button colorScheme="red" onClick={() => removeField(i, "sitemaps", null, idx)}>-</Button>
                      </HStack>
                    ))}
                    <Button colorScheme="blue" onClick={() => addField(i, "sitemaps", null)}>+</Button>      
                  </Box>

                  {/* Custom Sections */}
                  <Box mb="20px">
                    <Heading as="h1" size="md" mb="10px">{t('knowledge_base.custom_sections')}</Heading>
                    {assistant.prompt.documentation.custom_sections.map((field, idx) => (
                      <HStack key={`custom_section_${assistant.assistant_id}_${idx}`} spacing="10px" mb="10px">
                        <Textarea 
                          value={field} 
                          onChange={(e) => handleChange(i, "custom_sections", null, e.target.value, idx)} 
                          resize="none"
                          minHeight="40px"
                          width="100%"
                          overflow="hidden"
                          spellCheck={false}
                          onFocus={(e) => e.target.style.height = `${e.target.scrollHeight}px`}
                          onBlur={(e) => e.target.style.height = '40px'}
                        />
                        <Button colorScheme="red" onClick={() => removeField(i, "custom_sections", null, idx)}>-</Button>
                      </HStack>
                    ))}
                    <Button colorScheme="blue" onClick={() => addField(i, "custom_sections", null)}>+</Button>
                  </Box>

                  <Button type="submit" colorScheme="blue" mt="20px" mb="40px" onClick={() => saveDocumentation(assistant.assistant_id, assistant.prompt.documentation)}>{t('save_button')}</Button>
                </Box>
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      <ExpiredTokenModal isOpen={tokenExpModalOpen} onClose={handleTokenExpModalConfirm} />
    </Box>
  );
};

export default KnowledgeBase;