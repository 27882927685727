import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdCoPresent,
  MdHome,
  MdLock,
  MdLogout,
  MdMessage,
  MdSettings,
  MdSettingsSuggest,
  MdEditDocument,
  MdLightbulb,
} from "react-icons/md";
import { GiWeightLiftingUp } from "react-icons/gi";

// Admin Imports
import MainDashboard from "views/admin/default";
import Conversations from "views/admin/conversations";
import SandBox from "views/admin/sandbox";
import ChatbotSettings from "views/admin/chatbotSettings";
import GeneralSettings from "views/admin/generalSettings";
import Leads from "views/admin/leads";
import KnowledgeBase from "views/admin/knowledgeBase";
import Training from "views/admin/training";

// Auth Imports
// import SignInCentered from "views/auth/signIn";
import SignIn from "views/auth/signIn/index.jsx";
import SignUp from "views/auth/signUp";
import ForgotPassword from "views/auth/forgot-password";


const routes = [
  {
    name: "capture",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    isActive: true,
  },
  {
    name: "conversations",
    layout: "/admin",
    path: "/conversations",
    icon: (
      <Icon
        as={MdMessage}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Conversations,
    secondary: true,
    isActive: true,
  },
  {
    name: "leads",
    layout: "/admin",
    path: "/leads",
    icon: <Icon as={MdLightbulb} width='20px' height='20px' color='inherit' />,
    component: Leads,
    isActive: true,
  },
  {
    name: "sandbox",
    layout: "/admin",
    icon: <Icon as={MdCoPresent} width='20px' height='20px' color='inherit' />,
    path: "/sandbox",
    component: SandBox,
    isActive: true,
  },
  {
    name: "knowledge_base",
    layout: "/admin",
    path: "/knowledge_base",
    icon: <Icon as={MdEditDocument} width='20px' height='20px' color='inherit' />,
    component: KnowledgeBase,
    isActive: true,
  },
  {
    name: "training",
    layout: "/admin",
    path: "/training",
    icon: <Icon as={GiWeightLiftingUp} width='20px' height='20px' color='inherit' />,
    component: Training,
    isActive: true,
  },
  {
    name: "chatbot_settings",
    layout: "/admin",
    path: "/chatbot-settings",
    icon: <Icon as={MdSettingsSuggest} width='20px' height='20px' color='inherit' />,
    component: ChatbotSettings,
    isActive: true,
  },
  {
    name: "general_settings",
    layout: "/admin",
    path: "/general-settings",
    icon: <Icon as={MdSettings} width='16px' height='16px' color='inherit' />,
    component: GeneralSettings,
    isActive: true,
  },
  {
    name: "signin",
    layout: "/auth",
    path: "/sign-in",
    icon: (
      <Icon as={MdLock} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
    hide: true
  },
  {
    name: "signup",
    layout: "/auth",
    path: "/sign-up",
    icon: (
      <Icon as={MdLock} width='16px' height='16px' color='inherit' />
    ),
    component: SignUp,
    hide: true
  },
  {
    name: "forgotpwd",
    layout: "/auth",
    path: "/forgot-password",
    icon: (
      <Icon as={MdLock} width='16px' height='16px' color='inherit' />
    ),
    component: ForgotPassword,
    hide: true
  }
];

export const Logout = [
  {
    name: "logout",
    layout: "/auth",
    path: "/sign-out",
    icon: (
      <Icon as={MdLogout} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
  }
];
export default routes;
